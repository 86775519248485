.loadingComponent {
  width: 100vw;
  height: 100vh;
  //   background: linear-gradient(135deg, #355c7d, #6c5b7b, #c06c84);
  // background: linear-gradient(135deg, #000033, #003333);
  background: linear-gradient(90deg, #DAF6FF 0%, #EDDEFF 100%);
  position: relative;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.20);
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('https://yunheai.oss-cn-hangzhou.aliyuncs.com/assets/load-light-bg.png');
  }

  :global {
    .loading-wave {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .square-gradient {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 72px;
      height: 72px;
      gap: 6px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
}
.loadingComponentBorder{
  border-radius: 12px;
}
@primary-color: #1DA57A;