.header {
  position: relative;
  width: 100%;

  :global {
    .operate-container {
      // position: relative;

      -webkit-app-region: drag;
      height: 30px;
      position: absolute;
      width: 100%;

      .win-operate {
        position: fixed;
        right: 6px;
        top: 2px;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        -webkit-app-region:no-drag;

        &>div {
          margin-right: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          cursor: pointer;
          user-select: none;
          border-radius: 4px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }

        &>img {
          padding: 6px;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
        }
      }

      .max-operate {
        position: fixed;
        left: 16px;
        top: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        z-index: 999;
        -webkit-app-region:no-drag;

        &>div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          cursor: pointer;
          user-select: none;

          &>img {
            display: none;
          }
        }

        &:hover {
          &>div {
            &>img {
              display: block;
            }
          }
        }

        .close {
          background: #EE6A5F;
          border: 0.5px solid #C7493E;

          &>img {
            width: 12px;
            height: 12px;
            flex-shrink: 0;
          }
        }

        .retract {
          background: #F4BE50;
          border: 0.5px solid #CD9B35;

          &>img {
            width: 8px;
            height: 8px;
            flex-shrink: 0;
          }
        }

        .max {
          background: #5FC454;
          border: 0.5px solid #39A430;

          &>img {
            width: 7px;
            height: 7px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
@primary-color: #1DA57A;