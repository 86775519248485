body {
    width: 100%;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "微软雅黑",
    sans-serif;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#map {
    width: 100%;
    height: 100%;
}

/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-weight: normal;
    vertical-align: baseline;
    /* font-family: PingFang SC; */
    font-family: "SH-SC-MEDIUM";
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul,
li {
    list-style: none;
    margin: 0 !important;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* custom */
a {
    color: #7e8c8d;
    text-decoration: none;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: rgba(125, 125, 125, 0.7);
    border-radius: 6px;
    -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
    width: 5px;
    background-color: rgba(125, 125, 125, 0.7);
    border-radius: 6px;
    -webkit-border-radius: 6px;
}

html,
body {
    width: 100%;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "微软雅黑",
    sans-serif;
}

body {
    line-height: 1;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*清除浮动*/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: inline-block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clearfix {
    *zoom: 1;
}

/*隐藏*/
.dn {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit !important;
}


.content-popover {
    display: flex;
    flex-wrap: wrap;
    max-width: 474px;
    gap: 4px;
    max-height: 300px;
    overflow-y: auto;
}

.content-popover > img {
    cursor: pointer;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid;
    padding: 2px;
    border-color: transparent;
}

.content-popover > img:hover {
    border-color: var(--indigo-colors-indigo-06);
}

.content-popover .content-popover-item-active {
    border-color: var(--indigo-colors-indigo-06);
}

.prefix-role-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.code-item {
    font-size: 14px;
    color: var(--indigo-colors-indigo-06);
    cursor: pointer;
    line-height: 20px;
}

/* 编辑资料 */
.upload-preview-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-preview-wrap .upload-previewFile {
    width: 72px;
    height: 72px;
    margin-bottom: 8px;
    border-radius: 50%;
}

.upload-preview-wrap .light-btn {
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid var(--indigo-colors-indigo-06);
    background: var(--neutral-2);
    color: var(--indigo-colors-indigo-06);
    font-size: 14px;
    line-height: 20px;
}

/* 版本更新文本样式修改 */
.updates-wrap .updates-infos textarea.light-input {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid var(--neutral-6);
    background: var(--neutral-2);
}

/* 气泡框统一修改 */
.light-popover-inner {
    border-radius: 8px;
    background: var(--neutral-2);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

.light-popover-inner-content {
    padding: 16px;
    color: var(--neutral-1);
    font-size: 14px;
    line-height: 20px;
}

.light-popover-message-title {
    padding-left: 0;
}

.light-popover-message {
    padding: 0 0 12px;
}

.light-popover-message-title > div > p {
    color: var(--neutral-1);
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
}

.light-popover-buttons {
    margin-bottom: 0;
}

.light-popover-buttons .light-btn {
    padding: 6px 20px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    height: auto;
}

.light-popover-buttons .light-btn-default {
    border: 1px solid var(--neutral-6);
    background: var(--neutral-2);
    color: var(--neutral-3);
}

.light-popover-buttons .light-btn-primary {
    background: var(--linear-degrade);
    color: var(--neutral-2);
    font-weight: 500;
    border-color: var(--indigo-colors-indigo-04);
}

.light-popover-message-title .light-radio-group {
    color: var(--neutral-1);
    font-size: 14px;
    line-height: 20px;
}

.light-popover-message-title .light-radio-inner {
    border-radius: 2px;
    border: 1px solid var(--neutral-5);
}

.light-popover-message-title .light-radio-checked .light-radio-inner {
    position: relative;
    border-color: var(--indigo-colors-indigo-06);
    background: var(--indigo-colors-indigo-06);

}

.light-popover-message-title .light-radio-checked .light-radio-inner::after {
    display: none;
}

.light-popover-message-title .light-radio-checked .light-radio-inner::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 8px;
    border-right: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: 2;
}

.light-popover-message-title .light-radio-wrapper:hover .light-radio,
.light-radio:hover .light-radio-inner,
.light-radio-input:focus + .light-radio-inner {
    border-color: var(--indigo-colors-indigo-06);
}

.light-popover-message .anticon {
    display: none;
}

.global_message-w .ant-message-notice-content {
    background: var(--neutral-2) !important;
    color: var(--neutral-1) !important;
    font-size: 14px !important;
    line-height: 22px !important;
    border-radius: 2px;
    border: 1px solid var(--line-2);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    text-align: left !important;
    max-width: 664px !important;
}

.global_message-w .ant-message-custom-content {
    display: flex;
    align-items: center;
}

.global_message-w .ant-message-custom-content .anticon {
    font-size: 20px !important;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
    color: #7A67FF !important;
}

.ant-message-warning .anticon {
    color: rgba(255, 125, 0, 1) !important;
}

.light-spin-dot-item {
    background-color: #481EE3;
}
.markdown-body table tr:nth-child(2n){
    background-color: var(--color-canvas-default) !important;
}

.esp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
    overflow: hidden;
}

.esp2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
    overflow: hidden;
}
.my-custom-class-num-icon{
    position: relative;
    cursor: pointer;
    user-select: none;
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 50%;
    background: var(--Neutral-6, #E6E8EC);
    color: var(--Neutral-2, #23262F);
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    margin-right: 6px;
}
