.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  background-color: #5530F7;
  border-radius: 4px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 20px;
  }

  50% {
    height: 65px;
  }

  100% {
    height: 20px;
  }
}

.square-gradient .gradient {
  /* position: absolute; */
  width: 20px;
  height: 20px;
  border-radius: 0;
  background-color: rgba(85, 48, 247, 1);
  display: block;
  background-color: rgba(85, 48, 247, 1);
}

.square-gradient .gradient:nth-child(1) {
  left: 0px;
  top: 0;
}

.square-gradient .gradient:nth-child(1) {
  animation: a-gradient 1s infinite linear;
}

@keyframes a-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.6);
  }

  40% {
    transform: scale(0.4);
  }

  60% {
    transform: scale(0.2);
  }

  80% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(2) {
  left: 20px;
  top: 0;
}

.square-gradient .gradient:nth-child(2) {
  animation: b-gradient 1s infinite linear;
}

@keyframes b-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.6);
  }

  60% {
    transform: scale(0.2);
  }

  80% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(3) {
  left: 40px;
  top: 0;
}

.square-gradient .gradient:nth-child(3) {
  animation: c-gradient 1s infinite linear;
}

@keyframes c-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.6);
  }

  80% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(4) {
  left: 0px;
  top: 20px;
}

.square-gradient .gradient:nth-child(4) {
  animation: d-gradient 1s infinite linear;
}

@keyframes d-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.6);
  }

  60% {
    transform: scale(0.2);
  }

  80% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(5) {
  left: 20px;
  top: 20px;
}

.square-gradient .gradient:nth-child(5) {
  animation: e-gradient 1s infinite linear;
}

@keyframes e-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.6);
  }

  80% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(6) {
  left: 40px;
  top: 20px;
}

.square-gradient .gradient:nth-child(6) {
  animation: f-gradient 1s infinite linear;
}

@keyframes f-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(7) {
  left: 0px;
  top: 40px;
}

.square-gradient .gradient:nth-child(7) {
  animation: g-gradient 1s infinite linear;
}

@keyframes g-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.6);
  }

  80% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(8) {
  left: 20px;
  top: 40px;
}

.square-gradient .gradient:nth-child(8) {
  animation: h-gradient 1s infinite linear;
}

@keyframes h-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(9) {
  left: 40px;
  top: 40px;
}

.square-gradient .gradient:nth-child(9) {
  animation: i-gradient 1s infinite linear;
}

@keyframes i-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  70% {
    transform: scale(1);
  }

  80% {
    transform: scale(1);
  }

  90% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.square-gradient .gradient:nth-child(10) {
  left: 60px;
  top: 40px;
}

.square-gradient .gradient:nth-child(10) {
  animation: j-gradient 1s infinite linear;
}

@keyframes j-gradient {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}